import React from 'react'
import  { TransitionState } from "gatsby-plugin-transition-link"
import { motion } from 'framer-motion'

const AnimWrapper = ({children, variants, className, style}) => (



    <TransitionState>

        {({ mount }) => {

            return (
                <motion.div 
                    initial='hidden'
                    animate={
                        mount
                          ? 'visible'
                          : 'exit'
                      }
                    variants={variants}
                    className={className}
                    style={style}
                    >
                    {children}
                </motion.div>
            )
        }}

       
    </TransitionState>
)

export default AnimWrapper
