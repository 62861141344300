import React from 'react'

import Arrow from '../images/icons/arrow-right.svg'
import Icon from './icon'
import { Link } from "gatsby"
import TransitionLink from "gatsby-plugin-transition-link"

function getTransform(direction){
  switch (direction) {
    case "top":
      return "rotate(-90deg)"
      break
    case "left":
      return "rotate(180deg)"
      break
    case "bottom":
      return "rotate(90deg)"
      break
    default: return "none"
  }
}

const BigLink = ({className, to, children, direction, onClick, trigger, state, position = "left"}) => (

      <TransitionLink 
          className={className + " biglink " +  position }
          to={to}
          exit={{ length: 0.5, trigger: () => { if(trigger) trigger() } }}
          entry={{ delay: 0.5 , state: { type: state ? state : 'false' }}}
          onClick ={onClick}
        >
        { position !== "right" && <Icon> <Arrow style={{ transform: getTransform(direction)}} /> </Icon> }
        { children }
        { position === "right" && <Icon> <Arrow style={{ transform: getTransform(direction)}} /> </Icon> }
      </TransitionLink>
)

export default BigLink
