import React from 'react'


const cats = {
    "ux": {
        title: "UI/UX Design",
        color: "var(--teal)"
    },
    "frontend": {
        title: "Front End Development",
        color: "var(--pink)"
    },
    "motion": {
        title:"Motion Design",
        color: "var(--yellow)"
    },
    "graphics": {
        title: "Graphics Design",
        color: "var(--purple)"
    },
    "illustration" : {
        title: "Illustration",
        color: "var(--blue)"
    }
}

class Category extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            hovered : false 
        }
        this.showToolTip = this.showToolTip.bind(this);

    }

    showToolTip(){
        this.setState({ hovered : !this.state.hovered });
    }



    
    render() {
        const categoryTitle = cats[this.props.category].title
        const color = cats[this.props.category].color
        if(this.props.textOnly){
            return <span key={this.props.keyVal} className={this.props.category + " category-title"} onClick={this.props.onClick} style={{color: color }}>{categoryTitle}</span>
        }
        return (
            <div key={this.props.keyVal} className="category">
                <span className="tooltip" style={{backgroundColor: color }}>{categoryTitle}</span>
                <span className="category-dot" style={{backgroundColor: color}} title={categoryTitle}> </span>
            </div>
        )
    }
}

export default Category