import React from 'react'
import Nav from './nav'

import Icon from './icon'
import TransitionLink from "gatsby-plugin-transition-link"
import { motion } from 'framer-motion'

// import { ReactComponent as Arrow } from '../images/arrow-right.svg'

import LeftArrow from '../images/icons/arrow-left.svg'

import AnimWrapper from './animWrapper'
import BigLink from './bigLink'
import Category from './category'
import { Link } from 'gatsby'

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1},
  exit: { opacity: 0 }
}


const noMotion = {
  hidden: { opacity: 1 },
  visible: { opacity: 1},
  exit: { opacity: 1 }
}


const Header = ({title, slug, cats}) => (
    <header className="main-header">
      
 
      <div>
        <AnimWrapper variants={ variants }>
            <h1 className="main-title">
              <strong>
                { title && title === "Home" ?  "David Altreiter" : title}
              </strong>
              
              <motion.small variants={variants}>  
                {title && title === "Home" ? "the code savvy experience designer": ( 
                  <TransitionLink 
                    to="/"
                    exit={{ length: 0.5 }}
                    entry={{ delay: 0.5 }}
                    >
                    <LeftArrow/>
                    <span> David Altreiter </span>
                  </TransitionLink>
                )}
                {/* { 
                  cats ? cats.map(cat => (<Category key={cat} category={cat} textOnly={true} /> ))
                  : () 
                }   */}
              </motion.small>
            </h1>
          </AnimWrapper>
      </div> 
                {/* <Nav/> */}

    </header>
)

export default Header