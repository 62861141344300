import React, {useEffect, useRef} from 'react'
import { motion } from 'framer-motion'

const MailTo = (props) => {

  const mailtoLink = useRef(null)

  useEffect(() => {

    let output = 
        mailtoLink.current.getAttribute("data-name")     + "@" 
      + mailtoLink.current.getAttribute("data-domain")   + "."
      + mailtoLink.current.getAttribute("data-base")
    
    mailtoLink.current.href = "mailto:" + output
    mailtoLink.current.textContent = output

    mailtoLink.current.removeAttribute("data-name")
    mailtoLink.current.removeAttribute("data-domain")
    mailtoLink.current.removeAttribute("data-base")
    
  },[mailtoLink])

  return (
    <motion.a 
      ref={mailtoLink}
      href="/" 
      target="_blank" 
      className="mailto" 
      data-name={props.name} 
      data-domain={props.domain} 
      data-base={props.base}
      > 
      Email 
    </motion.a>
  )
}
export default MailTo