import React from 'react'
import { Link } from "gatsby"

import MailTo from "./mailTo"
import Icon from './icon'

import Twitter from '../images/icons/twitter.svg'
import Codepen from '../images/icons/codepen.svg'
import Dribbble from '../images/icons/dribbble.svg'
import Triangle from '../images/icons/triangle.svg'
import AnimWrapper from './animWrapper'
import CookieConsent from './cookieConsent'
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink'

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 }
}

const Footer = ({ slug }) => (

  <AnimWrapper variants={variants}>
    <CookieConsent />

    <footer key={slug} className="site-footer">
      <p className="mail">
        <Triangle />
          contact me via<br />
        <MailTo key={slug} name="david" domain="altreiter" base="at" />
      </p>

      <p>
        Built with Storyblok, Gatsby & ❤ <br />
        {new Date().getFullYear()} © David Altreiter - All Rights reserved  <br />
        <TransitionLink exit={{ length: 0.5 }} entry={{ delay: 0.5 }} to="/imprint/">Imprint</TransitionLink> - 
        <TransitionLink exit={{ length: 0.5 }} entry={{ delay: 0.5 }} to="/privacy-policy/">Privacy Policy</TransitionLink>


      </p>

      <div className="social-links">
        <a target="_blank" href="https://twitter.com/DavidAltreiter" rel="noopener noreferrer"><Icon><Twitter /></Icon> <span>/</span> @DavidAltreiter</a>
        <a target="_blank" href="https://dribbble.com/DavidAltreiter" rel="noopener noreferrer"><Icon><Dribbble /></Icon> <span>/</span> DavidAltreiter</a>
        <a target="_blank" href="https://codepen.io/altreiter" rel="noopener noreferrer"><Icon><Codepen /></Icon> <span>/</span> @altreiter</a>
      </div>
    </footer>
  </AnimWrapper>
)

export default Footer