import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from 'framer-motion'
import TransitionLink from "gatsby-plugin-transition-link"

const CookieConsent = props => {
  const [cookie, setCookie] = useState(0)

  useEffect(() => {
    if( decodeURIComponent(document.cookie).indexOf("consent=ok") > -1 ){
      setCookie(1)
    }else{
      setCookie(-1)
    }
  })

  useEffect(() => {
    if (cookie == 2) {
        let d = new Date()
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
    
        let expires = "expires=" + d.toUTCString()
        document.cookie = "consent=ok;" + expires + ";path=/"
    } 
  }, [cookie])


  return (
      <AnimatePresence> 
        {cookie < 0 &&
            <motion.div 
                initial={{ opacity: 0 , y: "100%", zIndex: 10000}}
                animate={{ opacity: 1, y: 0, zIndex: 10000}}
                exit={{ opacity: 0, y: "100%", zIndex: 10000 }}
                transition={{delay: 0.35, ease: "easeInOut", duration: 0.35}}
                className="consent"
            >
                <h2>Welcome to altreiter.at</h2>
                <p>
                    This Page uses Third Party Services from Google Fonts, Youtube & Vimeo that might set Cookies and/or store your Data. <br/>
                    Those Services are essential to view this Site. More can be seen here: <TransitionLink exit={{ length: 0.5 }} entry={{ delay: 0.5 }} to="/privacy-policy/">Privacy Policy</TransitionLink>
                </p>
                <button onClick={() => setCookie(2)}>Agree & Continue</button>

            </motion.div>
        }
    </AnimatePresence>
  )
}

export default CookieConsent

