import React from "react"
import PropTypes from "prop-types"


import Header from "./header"
import SEO from "./seo"
import AnimWrapper from './animWrapper'

import "../styles/main.sass"
import Footer from "./footer"

const variants = {
  hidden: { opacity: 1 },
  visible: { opacity: 1},
  exit: { opacity: 1 }
}
const Layout = ({title, children, slug, cats, description}) => {
  return (
    <div className="global-content">
      <SEO title={title} slug={slug} description={description}/>
      <main>
        <Header title={title} slug={slug} cats={cats}/>
          <AnimWrapper variants={variants}>
            <div className="page-content">
              {children}
            </div>
        </AnimWrapper>
      </main>
      <Footer slug={slug}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
