import React from 'react'
import BigLink from './bigLink';
import AnimWrapper from './animWrapper';

class Nav extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
            wasClicked: false,
            currentColor: "var(--highlight)",
            x: 0,
            y: 0
        }
        this.toggle = this.toggle.bind(this);
        this.setClick = this.setClick.bind(this);

    }

    toggle(){
        this.setState({ open : !this.state.open });
    }

    setClick(e){
        this.setState(
            {
                x: e.clientX,
                y: e.clientY,
                currentColor: window.getComputedStyle( e.target, ':before' ).getPropertyValue('background-color'),
                wasClicked: true
            }
        )
        
    }
    

    
    
    render() {
        return (

            <>
                <div className="navOverlay">
                    <AnimWrapper 
                        variants={
                            this.state.wasClicked ? {
                                hidden: { 
                                    opacity: 1,
                                    scale: 1,
                                },
                                visible: {
                                    opacity: 1,
                                    scale: 0.5,
                                    transition: {
                                        duration: 0.5
                                    }
                                },
                                exit: { 
                                    opacity: 1,
                                    scale: 1,
                                    transition: {
                                        duration: 0.5
                                    }
                                }
                            } : {
                                hidden: { 
                                    opacity: 0,
                                    scale: 0,
                                },
                                visible: {
                                    opacity: 0,
                                    scale: 0,
                                },
                                exit: { 
                                    opacity: 0,
                                    scale: 0
                                }
                            }
                    
                        }
                        style={{
                            left: `calc( ${this.state.x}px - 100vmax)`,
                            top: `calc( ${this.state.y}px - 100vmax)`,
                            background: this.state.currentColor,
                            width: "200vmax",
                            height: "200vmax",
                            borderRadius: "50%",
                            position: "absolute",
                        }}
                    />
                </div>

                <nav className="mobile-nav">
                    {/* <button className={this.state.open ? "nav-toggle open" : "nav-toggle closed"} onClick={this.toggle} > </button> */}
                    <ul className={this.state.open ? "nav-list open" : "nav-list closed"}>
                            <li className="home" > <BigLink  onClick={this.setClick} to={'/'} >  Start </BigLink></li>
                            <li className="works" > <BigLink onClick={this.setClick} to={'/works/'} > Works </BigLink></li>
                            <li className="about" > <BigLink  onClick={this.setClick} to={'/about/'} > About </BigLink></li>
                    </ul>
                    
                </nav>
            </>
        );
    }
}
export default Nav